import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SearchService} from '../../services/search.service';
import {FormControl} from '@angular/forms';
import {debounceTime, Subscription} from 'rxjs';
import {BlockType} from '../../../views/timeline/timeline.component';
import {BlockDetailSelection, BlockDetailService} from '../../services/block-detail.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  search = new FormControl();
  blocks: BlockType[] = [];
  filteredBlocks: BlockType[] = [];
  emptyResults = false;
  highlightedSearch = '';
  screenWidth: number = window.innerWidth;

  constructor(public dialogRef: MatDialogRef<SearchModalComponent>,
              private searchService: SearchService,
              private blockDetailService: BlockDetailService) { }

  ngOnInit(): void {

    this.subscriptions.push(
      this.searchService.getBlocks().subscribe(output => {
        this.blocks = output;
      }),

      this.blockDetailService.getBlockDetailSelection().subscribe((output: BlockDetailSelection) => {
        this.closeDialog();
      }),

      this.search.valueChanges.pipe(
        debounceTime(300)
      ).subscribe(output => {
        sessionStorage.setItem('search', output);
        this.highlightedSearch = this.search.value;

        this.filteredBlocks = [];
        if (output !== '') {
          this.blocks.forEach(block => {
            const parsed = JSON.stringify(block.coverText.document).toLowerCase();
            if (parsed.includes(output.toLowerCase())) {
              this.filteredBlocks.push(block);
            }
            this.filteredBlocks.sort((a, b) => {
              const dateA = new Date(a.beginDate.split('-').reverse().join('-'));
              const dateB = new Date(b.beginDate.split('-').reverse().join('-'));
              return dateA.getTime() - dateB.getTime();
            });
          });

          this.emptyResults = this.filteredBlocks.length === 0;
        }
      }),
    );

    this.search.setValue(sessionStorage.getItem('search') || '');
  }

  closeDialog() {
    this.dialogRef.addPanelClass('fly-out-dialog');
    this.dialogRef.close();
  }

  goToBlock(id: string) {
    this.searchService.setActiveBlockId(id);
  }

  preventDefault($event: SubmitEvent) {
    $event.preventDefault();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    if (window.innerWidth < 1279) {
      this.closeDialog();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


}
